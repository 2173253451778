<template>
  <div class="upload-page">
    <div class="intro">
      <!-- 欢迎来到CTCMB测评平台!提交后,每个类别和子类别的准确性细分将显示在下面。 -->
    </div>
    <div class="rules">
      <p class="title"><b>提交格式要求</b></p><br />
      <ul>
        <li>
          我们的评估基准包括11个数据集，每个数据集包含若干个题目，这些题目的answer字段为空。一个简短的示例如下：
        </li>
        <br />
        <pre>
{"question": "何谓血瘀?血瘀是如何形成的?", "answer": ""}
{"question": "肾“其华在发”有何理论依据?", "answer": ""}
{"question": "奇经八脉有何主要生理功能?", "answer": ""}
        </pre>

        <li>
          我们的评估代码要求接受下面的数据格式:<br /><br />
          <pre>
{"question": "何谓血瘀?血瘀是如何形成的?", "answer": "血瘀是指血液运行不畅、停滞于血脉之中而形成的一种病理状态。其主要表现为皮肤出现青紫斑块、疼痛等症状。血瘀的形成原因包括外伤、情绪波动等因素，也与体内气虚、阳虚等内在因素有关。治疗上应以活血化瘀为主，可采用针灸、中药等方式进行调理。"}
{"question": "肾“其华在发”有何理论依据?", "answer": "《黄帝内经》中记载：“人之五脏六腑、四肢百骸皆禀于自然，而血气亦各有所主。心藏神，肺藏魄，肝藏魂，脾藏意，肾藏志，此五者，皆藏于精。”其中，“肾藏志”，即指肾脏所藏之精神意志。“其华在发”，则说明肾脏的精神状态反映在外表上，即头发的状态。因此，从中医的角度来看，肾“其华在发”的理论依据是《黄帝内经》中的“五脏六腑、四肢百骸皆禀于自然，而血气亦各有所主。心藏神，肺藏魄，肝藏魂，脾藏意，肾藏志，此五者，皆藏于精。”这一理论认为，人体的各种生理功能都与五脏有关，而头发的状态则是由肾脏所支配的。因此，肾脏的精神状态可以通过头发来表现出来。"}
{"question": "奇经八脉有何主要生理功能?", "answer": "奇经八脉是中医学中的重要理论之一，其主要生理功能包括调节全身气血运行、维持脏腑功能平衡、促进人体阴阳协调等方面。其中，任督二脉是奇经八脉的核心，通过调节心肾之间的水火相济关系，达到调和阴阳、平衡气血的目的；而冲任二脉则与女性生殖系统密切相关，可以调节月经、孕育胎儿等生理过程；带脉则是人体腹部的一条横行脉络，具有固护腰腹、调节脾胃等功能；阴维脉则为人体阴气的主要通道，可以调节人体阴液的，维持人体阴液的正常代谢；阳维脉则为人体阳气的主要通道，可以调节人体阳气的正常代谢。总之，奇经八脉在人体内发挥着重要的生理作用，对人体健康有着不可忽视的影响。"}
          </pre>
          本质上是将answer字段使用模型的回答进行填充。这些的数据被保存在mid.jsonl文件中。<br /><br />
        </li>
        <li>我们拥有11个数据集，我们期待采用以下的命名方式提交</li>
        <pre>

   一级目录 （模型名）
├── A_problem
│   └── mid.jsonl
├── B_problem
│   └── mid.jsonl
├── C_problem
│   └── mid.jsonl
├── D_problem
│   └── mid.jsonl
├── 2.TCM-DS Dataset
│   └── mid.jsonl
├── 3.TCM-DID Dataset
│   └── mid.jsonl
├── 4.TCM-FT
│   └── mid.jsonl
├── 5.TCM-CHGD
│   └── mid.jsonl
├── 6.Med-Treat
│   └── mid.jsonl
├── 7.TCM-Clin
│   └── mid.jsonl
├── 8.TCMeEE
│   └── mid.jsonl
├── 9.TCM-LitData
│   └── mid.jsonl
├── 10.TCM-SRT
│   └── mid.jsonl
└── 12.SPD
    └── mid.jsonl
  </pre
        >
      </ul>
    </div>

    <!-- 上传 -->
    <div class="upload-box d-flex">
      <el-upload
        class="upload-demo"
        ref="upload"
        action="https://jsonplaceholder.typicode.com/posts/"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :file-list="fileList"
        :auto-upload="false"
        accept=".zip"
        :limit="1"
      >
        <el-button slot="trigger" size="small" type="primary"
          >选择文件</el-button
        >
        <el-button
          style="margin-left: 12px"
          size="small"
          type="success"
          @click="submitUpload"
          >上传并处理</el-button
        >

        <el-button
          style="margin-left: 12px"
          size="small"
          type="info"
          @click="submitUpload"
          >下载评估结果</el-button
        >
      </el-upload>
    </div>
    <div class="rules">
      你需要采用压缩包的方式提交。比如 <b>gemini-1.5-pro.zip</b> <br /><br />
      1.你需要点击“选择文件”按钮，选择本地的压缩包文件。 <br />
      2.点击“上传并处理”按钮，通常需要30-60min钟即可对答案进行评分。<br />
      3.处理完成后，你可以点击“下载评估结果”按钮下载评估结果。<br />
      4.可以将结果分数发送至xxx@163.com进行宣传。<br />
    </div>

    <div class="result">
      <h2>Result</h2>
      <div class="rules"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fileList: [],
    };
  },
  methods: {
    submitUpload() {
      console.log("upload");
      this.$refs.upload.submit();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-page {
  margin-top: 24px;
  width: 100%;
  min-height: 900px;
  .intro {
    margin-bottom: 32px;
    text-align: left;
  }
  .rules {
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 20px;
    text-align: left;
    font-family: monospace;
    overflow: auto;
    ul {
      padding-left: 20px;
    }
  }
  .upload-box {
    margin-bottom: 24px;
    .upload-demo {
      display: flex;
      justify-content: start;
      align-items: center;
    }
  }
  .result {
    text-align: left;
    h2 {
      margin-bottom: 10px;
    }
  }
}
</style>