<template>
  <div class="cmb-page">
    <div class="cmb-page_header container">
      <div class="left">
        <div class="title">CTCMBenchmark</div>
        <div class="desc">
          “通用·信任”中医大模型评测系统  CTCMB (Comprehensive TCM Benchmark)
        </div>
        <div class="link">中山大学-招联数字金融联合研究中心发布</div>
      </div>
      <div class="right">
        <img class="img" src="@/assets/images/cmb/header_icon.png" fit="fill" />
      </div>
    </div>

    <!-- tabs -->
    <div class="cmb-page_content container">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane name="1">
          <span slot="label"><i class="el-icon-notebook-1"></i> 关于CTCMB</span>
          <CMBAbout></CMBAbout>
        </el-tab-pane>
        <el-tab-pane name="2">
          <span slot="label"><i class="el-icon-data-line"></i> 排行榜单</span>
          <CMBLeaderBoard></CMBLeaderBoard>
        </el-tab-pane>
        <el-tab-pane name="3">
          <span slot="label"><i class="el-icon-folder-add"></i> 上传模型</span>
          <CMBUpload></CMBUpload>
        </el-tab-pane>
        <el-tab-pane name="4" disabled>
          <div slot="label" class="" @click="jumpPaper()">
            <span><i class="el-icon-document"></i> 论文报告</span>
          </div>
        </el-tab-pane>
        <el-tab-pane name="5" disabled>
          <div slot="label" class="" @click="jumpGitHub()">
            <span slot="label"><i class="el-icon-link"></i> GitHub</span>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import CMBAbout from "@/components/cmb/About";
import CMBLeaderBoard from "@/components/cmb/LeaderBoard";
import CMBUpload from "@/components/cmb/Upload";

export default {
  components: { CMBAbout, CMBLeaderBoard, CMBUpload },
  data() {
    return {
      activeName: "1",
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    jumpPaper() {
      window.open("");
    },
    jumpGitHub() {
      window.open("https://github.com/Wayyuanyuan/CTCMB/tree/main");
    },
  },
};
</script>

<style lang="scss" scoped>

::v-deep .el-tabs__item.is-disabled{
  color: #303133;
  cursor: pointer;
}
.cmb-page {
  .cmb-page_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 400px;
    padding-top: 60px;
    background-color: #292247;
    .left {
      text-align: left;
      .title {
        height: 64px;
        font-weight: 500;
        font-size: 56px;
        color: #ffffff;
        line-height: 64px;
        margin-bottom: 29px;
      }
      .desc {
        height: 32px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #ffffff;
        margin-bottom: 18px;
      }
      .link {
        width: fit-content;
        padding: 7px 24px;

        background: linear-gradient(90deg, #52497b 0%, #6554b0 100%);
        border-radius: 31px;
        color: #fff;
      }
    }
    .right {
      .img {
        width: 275.8px;
        height: 250.22px;
      }
    }
  }

  .cmb-page_content {
    margin-top: 16px;
  }
}
</style>